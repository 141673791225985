<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="content d-flex flex-column flex-lg-row justify-center mb-16">
      <div class="left ml-lg-8 mr-lg-5">
        <div class="PromoImage">
          <img src="@/assets/promoImage/promo10.jpg" alt="" />
        </div>
      </div>
      <div class="right mt-lg-0 mt-9">
        <div class="title mt-2 mb-lg-6">
          <span :class="{ darkFont: $vuetify.theme.dark }"
            >Game of Interchain NFTs
          </span>
        </div>
        <div class="desc mb-lg-6">
          <div class="font-15" :class="{ darkFont: $vuetify.theme.dark }">
            The Game of Interchain NFTs is a celebration and realization of the
            collective efforts and hard work invested in promoting the ICS-721
            protocol. With the Uptick on IRISnet NFT Marketplace and the Uptick
            Chain NFT Marketplace now both fully supporting cross-chain
            functionality, we are bringing the previous visions and concepts
            into a tangible reality for our community, and connecting the dots
            between technological advancement and practical user engagement.
          </div>
        </div>

        <div class="claimtime mb-lg-8 mb-4">
          <div>
            <span :class="{ darkFont: $vuetify.theme.dark }"
              >This event features two unique cross-chain games, each linked to
              its own marketplace. Participants are encouraged to join both
              games to earn double rewards.
            </span>
          </div>
        </div>
        <div class="claimtime mb-lg-2">
          <div>
            <span :class="{ darkFont: $vuetify.theme.dark }"
              >Event Start: February 21, 2024, at 17:00 (UTC+8)</span
            >
          </div>
        </div>
        <div class="claimtime mb-lg-9 mb-6">
          <div>
            <span :class="{ darkFont: $vuetify.theme.dark }"
              >Event End: March 13, 2024, at 17:00 (UTC+8)</span
            >
          </div>
        </div>
        <div class="title-25" :class="{ darkFont: $vuetify.theme.dark }">Game #1 | Starting from Uptick on IRISnet Marketplace</div>
        <div class="prize mt-lg-3 mt-5">
          <div>
            <div class="rules" :class="{ darkFont: $vuetify.theme.dark }">
                How to Participate?
            </div>
            <div class="titles" :class="{ darkFont: $vuetify.theme.dark }">
                1. Collect the  <a target="blank" href="https://irisnet.upticknft.com/saledetail?contractAddress=uptickab7dc9849c2d5c9c04f7881710fd8e58&owner=iaa1utsw7gm82s5wecmvthjg5p58r6dmf7s8s0ls0z"
                >free event NFT</a
              >  listed by the Uptick Official Account
            </div>
            <div class="titles" :class="{ darkFont: $vuetify.theme.dark }">
       
              2. Cross-chain transfer event NFT to the  <a target="blank" href="https://upticknft.com/"
                >Uptick Chain NFT Marketplace</a
              > 
             
            </div>
            <div class="titles" :class="{ darkFont: $vuetify.theme.dark }">
                3. List NFT for 316 UPTICK on the Uptick Chain NFT Marketplace
            </div>
            <div class="titles" :class="{ darkFont: $vuetify.theme.dark }">
                4. Post on X/Twitter: <br/>
                  <span class="ml-2" :class="{ darkFont: $vuetify.theme.dark }">  · Include a link to the NFT you successfully transferred in your post </span> <br/>
                  <span class="ml-2" :class="{ darkFont: $vuetify.theme.dark }">· Tag  <a target="blank" href=" https://twitter.com/uptickproject">@uptickproject</a>   and  <a target="blank" href="https://twitter.com/irisnetwork">@irisnetwork</a>  </span>   <br/>
                  <span  class="ml-2" :class="{ darkFont: $vuetify.theme.dark }"> · Include the hashtag: #InterchainNFT in your post</span>
            </div>
            <div class="titles" :class="{ darkFont: $vuetify.theme.dark }">
                5. Submit  <a target="blank" href="https://docs.google.com/forms/d/e/1FAIpQLSexT1fyc6Ny4m2f8uRygQA4jM7AtsBxV575xDvWWIUg4d2CPg/viewform"> Google Form #1</a>
            </div>
          </div>
        </div>
        <div class="prize mt-lg-7 mt-6">
          <div>
            <div class="rules" :class="{ darkFont: $vuetify.theme.dark }">
                Event Rewards
            </div>
            <div class="claimtime mb-lg-2">
          <div>
            <span :class="{ darkFont: $vuetify.theme.dark }"
              >Complete steps 1-3 to receive:
            </span>
          </div>
        </div>
        <div class="title-15" :class="{ darkFont: $vuetify.theme.dark }">· Token Reward A : 300 UPTICK </div>
        <div class="title-15" :class="{ darkFont: $vuetify.theme.dark }">· Interchain NFT Pioneer NFT</div>
          </div>
        </div>
        <div class="prize mt-lg-3 mt-3">
          <div>
            
            <div class="claimtime mb-lg-2 ">
          <div>
            <span :class="{ darkFont: $vuetify.theme.dark }"
              > Complete steps 4-5 to receive:
            </span>
          </div>
        </div>
        <div class="title-15" :class="{ darkFont: $vuetify.theme.dark }">· Token Reward B: 200 UPTICK </div>
          </div>
        </div>
        <div class="btn mt-6 ">
          <button class="claim" @click="toIRISnet"  >Get Free Event NFT</button>
        </div>

        <div class="title-25 mt-14" :class="{ darkFont: $vuetify.theme.dark }">Game #2 | Starting from Uptick Chain Marketplace</div>
        <div class="prize mt-lg-3 mt-5">
          <div>
            <div class="rules" :class="{ darkFont: $vuetify.theme.dark }">
                How to Participate?
            </div>
            <div class="titles" :class="{ darkFont: $vuetify.theme.dark }">
                1. Collect the  <a target="blank" href="https://uptick.upticknft.com/dropPage/0x9fc96cd6d5e070bfdff9aed264fd52282669175d"
                >free event NFT</a
              >  listed by the Uptick Official Account
            </div>
            <div class="titles" :class="{ darkFont: $vuetify.theme.dark }">
              2. Cross-chain transfer event NFT to the  <a target="blank" href="https://irisnet.upticknft.com"
                > Uptick on IRISnet NFT Marketplace</a
              > 
             
            </div>
            <div class="titles" :class="{ darkFont: $vuetify.theme.dark }">
                3. List NFT for 316 IRIS on the Uptick on IRISnet NFT Marketplace
            </div>
            <div class="titles" :class="{ darkFont: $vuetify.theme.dark }">
                4. Post on X/Twitter: <br/>
                  <span class="ml-2" :class="{ darkFont: $vuetify.theme.dark }"> · Include a link to the NFT you successfully transferred in your post </span> <br/>
                  <span class="ml-2" :class="{ darkFont: $vuetify.theme.dark }">· Tag  <a target="blank" href=" https://twitter.com/uptickproject">@uptickproject</a>   and  <a target="blank" href="https://twitter.com/irisnetwork">@irisnetwork</a>  </span>   <br/>
                  <span  class="ml-2" :class="{ darkFont: $vuetify.theme.dark }"> · Include the hashtag: #InterchainNFT in your post</span>
            </div>
            <div class="titles" :class="{ darkFont: $vuetify.theme.dark }">
                5. Submit  <a target="blank" href="https://forms.gle/Zp7G6FADhMsZvTYV6"> Google Form #2</a>
            </div>
          </div>
        </div>
        <div class="prize mt-lg-7 mt-6">
          <div>
            <div class="rules" :class="{ darkFont: $vuetify.theme.dark }">
                Event Rewards
            </div>
            <div class="claimtime mb-lg-2">
          <div>
            <span :class="{ darkFont: $vuetify.theme.dark }"
              >Complete steps 1-3 to receive:
            </span>
          </div>
        </div>
        <div class="title-15" :class="{ darkFont: $vuetify.theme.dark }">· Token Reward A : 300 IRIS  </div>
        <div class="title-15" :class="{ darkFont: $vuetify.theme.dark }">· Interchain NFT Pioneer NFT</div>
          </div>
        </div>
        <div class="prize mt-lg-3 mt-3">
          <div>
          
            <div class="claimtime mb-lg-2">
          <div>
            <span :class="{ darkFont: $vuetify.theme.dark }"
              > Complete steps 4-5 to receive:
            </span>
          </div>
        </div>
        <div class="title-15" :class="{ darkFont: $vuetify.theme.dark }">· Token Reward B: 200 IRIS </div>
          </div>
        </div>
        <div class="btn mt-6 ">
          <button class="claim"  @click="toUptick">Get Free Event NFT</button>
        </div>
        <div class="notes" :class="{ darkFont: $vuetify.theme.dark }">Notes</div>
        <div class="note_des" :class="{ darkFont: $vuetify.theme.dark }">· There are <span>500</span>  reward slots available for both Game #1 and Game #2, totaling 1000 rewards, and each game’s rewards are <span> allocated separately.</span></div>
        <div class="note_des" :class="{ darkFont: $vuetify.theme.dark }">· Each participant is eligible to receive a maximum of  <span>one reward from Game #1</span>  and <span>  one reward from Game #2.</span></div>
        <div class="note_des" :class="{ darkFont: $vuetify.theme.dark }">· <span>Token Reward A </span> will be awarded via the official purchase of your listed NFT by the team, and both <span>Token Reward B </span>  and the <span>Interchain NFT Pioneer NFT </span>  can be claimed by the qualifying participants after the event concludes.</div>
    
      </div>
    </div>
  </div>
</template>

<script>
let drawAtomEndTimeStamp;
export default {
  data() {
    return {
      d1: "",
      d2: "",
      h1: "",
      h2: "",
      m1: "",
      m2: "",
      s1: "",
      s2: "",
      isPhotographyEnd: false,
      isStart: false,
      isTimeOut: true,
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
    this.Time();
    var timestamp = new Date().valueOf();
    let nowTimeStamp = timestamp;
    // 接受时间时间
    drawAtomEndTimeStamp = 1698138000000;
    if (nowTimeStamp > drawAtomEndTimeStamp) {
      this.isPhotographyEnd = true;
    } else {
      this.isPhotographyEnd = false;
    }
  },
  methods: {
    async toIRISnet() {
      // this.isClicked = true
      window.open(
        "https://irisnet.upticknft.com/saledetail?contractAddress=uptickab7dc9849c2d5c9c04f7881710fd8e58&owner=iaa1utsw7gm82s5wecmvthjg5p58r6dmf7s8s0ls0z",
        "_blank"
      );
    },
    async toUptick() {
      // this.isClicked = true
      window.open(
        "https://uptick.upticknft.com/dropPage/0x9fc96cd6d5e070bfdff9aed264fd52282669175d",
        "_blank"
      );
    },
    
    getTime() {
      // Atom倒计时结束时间1667361600000
      let photographyEndTimeStamp = 1698138000000; //
      this.now = new Date().valueOf();
      this.end = photographyEndTimeStamp;
      if (this.now < this.end) {
        this.seconds = (this.end - this.now) / 1000;
        this.isTimeOut = true;
      } else {
        this.isTimeOut = false;

        //  if(this.now > drawAtomStartTimeStamp && this.now<drawAtomEndTimeStamp ){
        //    this.isDraw = true
        //  }else{

        //     this.isDraw = false
        //  }
      }
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      let day = d < 10 ? "0" + d : d;
      this.d1 = String(day).split("")[0];
      this.d2 = String(day).split("")[1];
      //let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
      let h = parseInt((this.seconds / (60 * 60)) % 24);
      let hour = h < 10 ? "0" + h : h;
      this.h1 = String(hour).split("")[0];
      this.h2 = String(hour).split("")[1];
      let m = parseInt((this.seconds / 60) % 60);
      let minutes = m < 10 ? "0" + m : m;
      this.m1 = String(minutes).split("")[0];
      this.m2 = String(minutes).split("")[1];
      let s = parseInt(this.seconds % 60);
      let second = s < 10 ? "0" + s : s;
      this.s1 = String(second).split("")[0];
      this.s2 = String(second).split("")[1];
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
          this.countDown();
        } else {
          this.getTime();
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {

  .content {
    margin-top: 50px;
    .notes{
        font-family: Helvetica;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 0px;
	color: #270645;
    margin-top:50px;
    }
    .note_des{
        font-family: Helvetica;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 25px;
	letter-spacing: 0px;
	color: #270645;
    span{
        font-weight: bold !important;
    }
    }
    .title-15 {
    font-family: Helvetica;
	font-size: 15px;
	font-weight: normal;
	letter-spacing: 0px;
    line-height: 25px;
	color: #270645;
    margin-left: 8px;
    }
    .title-25{
        font-family: Helvetica;
	font-size: 25px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 0px;
	color: #270645;
    }
    .font-16 {

      font-family: Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 23px;
      letter-spacing: 0px;
      color: #270645;
      a {
        color: #7800f4;
      }
    }
    .left {
      width: 560px;
      // height: 550px;
      img {
        width: 470px;

        display: block;
        object-fit: contain;
        cursor: pointer;
      }
      .timer {
        // max-width:305px;
        // margin:17px auto 21px;
        justify-content: center;
        margin-top: 17px;
        margin-left: -90px;
        .mao {
          font-family: Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: 0px;
          color: #270645;
        }
        .bom {
          font-family: Helvetica;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #766983;
          text-align: center;
          margin-top: 7px;
        }
        span {
          width: 27px;
          height: 36px;
          display: inline-block;
          text-align: center;
          line-height: 36px;
          background-color: #270645;
          border-radius: 5px;
          font-family: Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
      .endDesc {
        margin-top: 20px;
        color: red;
        text-align: center;
      }
      
    }
    .right {
      width: 710px;
      // height: 613px;
      .btn {
        border-radius: 25px;
      
        .notClaim {
          width: 276px;
          height: 51px;
          border-radius: 25px;
          background-color: #766983;
          font-family: Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;
        }

        .claim {
          width: 276px;
          height: 51px;
          border-radius: 25px;
          background-image: linear-gradient(#270645, #270645),
            linear-gradient(
              90deg,
              #d300fd 0%,
              #a439fe 26%,
              #7471ff 52%,
              #00fdcf 100%
            ),
            linear-gradient(#270645, #270645);
          background-blend-mode: normal, normal, normal;

          font-family: Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
      .title {
        span {
          font-family: Helvetica;
          font-size: 45px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 45px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .desc {
        .celebrate {
          width: 620px;
          font-family: Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 23px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .desc {
        .font-15 {
   
          font-family: Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 23px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .claimtime {
        span {
          font-family: Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
        div {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 18px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .formore {
        font-family: Helvetica;
        font-style: italic;

        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #270645;
      }
      .collection {
        span {
          font-family: Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #270645;
        }
        .titlecollect {
          font-family: Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #270645;
        }
        .title-15 {
          font-family: Helvetica;
          font-size: 15px;
          font-weight: normal;
          letter-spacing: 0px;
          line-height: 20px;
          color: #270645;
        }
      }

      .prize {
        .rules {
          font-family: Helvetica;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 22px;
          color: #270645;
          margin-bottom: 15px;
        }
        .titles {
          font-family: Helvetica;
          font-size: 15px;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 25px;
          color: #270645;
          a {
            color: #7800f4;
            text-decoration: none;
          }
        }
      }
      .event {
        .eventTitle {
          font-family: Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #270645;
        }
        .title-15 {
          font-family: Helvetica;
          font-size: 15px;
          font-weight: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
    }
  }
  &.mobile {
    .content {
      margin-top: 30px;
      .left {
        max-width: 100%;
        min-height: 437px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
          cursor: pointer;
        }
        .timer {
          // width:90%;
          // margin-left:14%;
          justify-content: center;
          margin-top: 17px;
          width: 100%;
          margin-left: 0px;
          span {
            width: 20px;
            height: 30px;
            font-size: 15px;
            line-height: 30px;
          }
        }
        .endDesc {
          width: 340px;
          margin-left: 7px;
          margin-top: 20px;
          color: red;
        }
        .btn {
          margin-left: 0px;
          .claim {
            width: 80%;
          }
        }
      }
      .right {
        max-width: 100%;
        min-height: 613px;
        .title {
          margin-bottom: 10px;
          span {
            font-size: 25px;
            line-height: 40px;
          }
        }
        .desc {
          // max-width:576px;
          margin-bottom: 28px;
        }
        .prize {
        //   min-height: 160px;
        }
        .claimtime {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
